<template>
  <b-sidebar
    id="store-product-filters"
    title="Filtros"
    shadow
    backdrop
    lazy
    hide-header
    body-class="p-2"
  >
    <!-- <div class="ratings mb-3">
      <h6 class="filter-title">Valor nutricional</h6>
      <div
        v-for="score in nutriScore"
        :key="score"
        @click="handleNutriScore(score)"
        class="d-flex justify-content-between w-100 mb-1"
      >
        <nutri-score :nutritionalInfo="score" />
        <i v-if="filters.nutriScore.includes(score)" class="fas fa-circle" />
      </div>
    </div> -->
    <div v-if="storeTypes.length > 0">
      <h6 class="filter-title mt-0 mb-1 text-xl">Tipos de tienda</h6>
      <div class="filter__container">
        <b-form-checkbox-group
          v-model="filters.storeTypes"
          class="categories-radio-group"
          stacked
          :options="beautyStoreTypes"
          text-field="name"
          value-field="id"
          @change="onChange"
        />
      </div>
    </div>

    <!-- Categories -->
    <div class="mt-2" v-if="categories.length > 0">
      <h6 class="filter-title mt-0 mb-1 text-xl">Categorías</h6>

      <div class="filter__container mt-2">
        <b-form-checkbox-group
          v-model="filters.categories"
          class="categories-radio-group"
          stacked
          :options="categories"
          text-field="name"
          value-field="id"
          @change="onChange"
        />
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import Vue from "vue"
import NutriScore from "@/@core/components/NutriScore.vue"

export default {
  components: {
    NutriScore,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    storeTypes: {
      type: Array,
      default: () => [],
      required: false,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      nutriScore: ["a", "b", "c", "d", "e"],
    }
  },
  methods: {
    handleNutriScore(score) {
      if (!this.filters.nutriScore.includes(score)) {
        this.filters.nutriScore.push(score)
      } else {
        this.filters.nutriScore.splice(
          this.filters.nutriScore.indexOf(score),
          1
        )
      }
    },
  },
  computed: {
    beautyStoreTypes() {
      return this.storeTypes.map((type) => {
        return {
          id: type,
          name: Vue.options.filters.storeType(type),
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-slider.scss";
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}

.filter__container {
  max-height: 400px;
  overflow-y: auto;
}
</style>
