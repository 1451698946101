<template>
  <b-modal
    :id="`store-product-add-modal-${storeProduct.id}-${uniqueId}`"
    title="Agregar producto"
    @hide="reset"
    @ok="handleOk"
    ok-title="Agregar"
    ok-variant="primary"
    :ok-disabled="
      selectedStoreProduct &&
      (selectedStoreProduct.inventory === '0.0' ||
        Number(selectedStoreProduct.inventory) <
          Number(selectedStoreProduct.units)) &&
      selectedStoreProduct.has_inventory
    "
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    scrollable
  >
    <store-product-item
      :product="product"
      :storeProduct="storeProduct"
      @update:selectedStoreProduct="selectedStoreProduct = $event"
    />
  </b-modal>
</template>

<script>
import StoreProductItem from "./StoreProductItem.vue"

export default {
  components: {
    StoreProductItem,
  },
  props: {
    storeProduct: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    uniqueId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedStoreProduct: {
        ...this.storeProduct,
        units: 1,
      }
    }
  },
  methods: {
    reset() {
      this.selectedStoreProduct = {
        ...this.storeProduct,
        units: 1,
      }
    },

    handleOk() {
      this.$emit("on-ok", this.selectedStoreProduct)
      this.reset()
    },
  },
}
</script>
